<template>
  <v-app id="inspire">
    <!-- <v-system-bar app>
      <v-spacer></v-spacer>

      <v-icon>mdi-square</v-icon>

      <v-icon>mdi-circle</v-icon>

      <v-icon>mdi-triangle</v-icon>
    </v-system-bar> -->
    <v-app-bar app color="white">
      <v-container class="py-0 fill-height" fluid>
        <div>
        <div>Invoice Pro</div>
        <span class="caption">Making Tax Digital</span>
        </div>
        <v-spacer></v-spacer>
        <v-responsive max-width="260">
          <v-text-field
            dense
            flat
            hide-details
            rounded
            solo-inverted
          ></v-text-field>
        </v-responsive>
      </v-container>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" class="app grey darken-3" dark app persistent>
      <v-sheet color="grey darken-3" class="pa-4">
        <v-avatar>
          <img src="../../assets/images/profile.png" size="54" alt="John" />
        </v-avatar>

        <div class="subtitle-1">Nigerian Motors 52</div>
        <div class="caption"><strong>Tax Identification Number (TIN)</strong> : VAT328071</div>
      </v-sheet>

      <v-divider></v-divider>
      <v-list nav dark class="caption">
        <v-list-item active-class="white--text" link to="/admin/invoice">
          <v-list-item-icon>
            <v-icon>mdi-view-dashboard</v-icon>
          </v-list-item-icon>
          <v-list-item-title class="font-weight-bold"
            >Dashboard</v-list-item-title
          >
        </v-list-item>
        <v-list-group
          no-action
          :value="false"
          color=""
          prepend-icon="send_to_mobile"
        >
          <template v-slot:activator>
            <v-list-item-content active-class="white--text">
              <v-list-item-title class="font-weight-bold"
                >Transactions</v-list-item-title
              >
            </v-list-item-content>
          </template>
          <v-list-item
            active-class="white--text"
            link
          >
            <v-list-item-title class="font-weight-bold"
              >Chart Of Accounts</v-list-item-title
            >
          </v-list-item>
          <v-list-item
            active-class="white--text"
            link
          >
            <v-list-item-title class="font-weight-bold"
              >Receipts</v-list-item-title
            >
          </v-list-item>
        </v-list-group>

        <v-list-group
          no-action
          :value="false"
          color=""
          prepend-icon="send_to_mobile"
        >
          <template v-slot:activator>
            <v-list-item-content active-class="white--text">
              <v-list-item-title class="font-weight-bold"
                >Sales</v-list-item-title
              >
            </v-list-item-content>
          </template>
          <v-list-item
            active-class="white--text"
            link
          >
            <v-list-item-title class="font-weight-bold"
              >All Sales</v-list-item-title
            >
          </v-list-item>
          <v-list-item
            active-class="white--text"
            link
          >
            <v-list-item-title class="font-weight-bold"
              >Invoices</v-list-item-title
            >
          </v-list-item>
           <v-list-item
            active-class="white--text"
            link
          >
            <v-list-item-title class="font-weight-bold"
              >Products & Services</v-list-item-title
            >
          </v-list-item>
        </v-list-group>

        <v-list-group
          no-action
          :value="false"
          color=""
          prepend-icon="send_to_mobile"
        >
          <template v-slot:activator>
            <v-list-item-content active-class="white--text">
              <v-list-item-title class="font-weight-bold"
                >Expenses</v-list-item-title
              >
            </v-list-item-content>
          </template>
          <v-list-item
            active-class="white--text"
            link
          >
            <v-list-item-title class="font-weight-bold"
              >All Expenses</v-list-item-title
            >
          </v-list-item>
          <v-list-item
            active-class="white--text"
            link
          >
            <v-list-item-title class="font-weight-bold"
              >Suppliers</v-list-item-title
            >
          </v-list-item>
           <v-list-item
            active-class="white--text"
            link
          >
            <v-list-item-title class="font-weight-bold"
              >Bills</v-list-item-title
            >
          </v-list-item>
        </v-list-group>

        <v-list-group
          no-action
          :value="false"
          color=""
          prepend-icon="send_to_mobile"
        >
          <template v-slot:activator>
            <v-list-item-content active-class="white--text">
              <v-list-item-title class="font-weight-bold"
                >Payroll</v-list-item-title
              >
            </v-list-item-content>
          </template>
          <v-list-item
            active-class="white--text"
            link
          >
            <v-list-item-title class="font-weight-bold"
              >Employees</v-list-item-title
            >
          </v-list-item>
           <v-list-item
            active-class="white--text"
            link
          >
            <v-list-item-title class="font-weight-bold"
              >Salary Runs</v-list-item-title
            >
          </v-list-item>
        </v-list-group>

        <v-list-item active-class="white--text">
          <v-list-item-icon>
            <v-icon>group</v-icon>
          </v-list-item-icon>
          <v-list-item-title class="font-weight-bold">Users</v-list-item-title>
        </v-list-item>
        <v-list-item
          active-class="white--text"
          link
        >
          <v-list-item-icon>
            <v-icon>supervisor_account</v-icon>
          </v-list-item-icon>
          <v-list-item-title class="font-weight-bold">Admin</v-list-item-title>
        </v-list-item>
        <v-list-item
          active-class="white--text"
          link
        >
          <v-list-item-icon>
            <v-icon>account_circle</v-icon>
          </v-list-item-icon>
          <v-list-item-title class="font-weight-bold"
            >Profile</v-list-item-title
          >
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <v-container fluid class="py-8 px-6">
        <router-view />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  data: () => ({
    cards: ["Today", "Yesterday"],
    drawer: null,
    links: [
      ["mdi-inbox-arrow-down", "Inbox"],
      ["mdi-send", "Send"],
      ["mdi-delete", "Trash"],
      ["mdi-alert-octagon", "Spam"],
    ],
  }),
};
</script>
<style>
.v-list-item__title {
    align-self: center;
    font-size: 14px;
}
</style>